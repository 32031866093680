$brand-color: #6891ae;
$background-gray: #eeeef0;
$font-family-base: Merriweather;
$font-size-base: 0.875rem !default;
$enable-rounded: false !default;
$primary: $brand-color !default;
$btn-font-family: sk-modernist;
$input-btn-padding-y: 1.075rem !default;
$input-btn-padding-x: 0.875rem !default;
$btn-padding-y: 0.938rem !default;
$btn-padding-x: 1.875rem !default;
$btn-box-shadow: 0 2px 14px 0 rgba(104, 145, 174, 0.5);
$input-btn-font-size: 0.75rem !default;
$link-hover-decoration: none !default;