@import "vars";

.cas {
    padding-left: 0 !important;
    padding-right: 0 !important;
    background-color: $background-gray;
  
    footer {
      font-family: sk-modernist;
      background-color: #2b2a2f;
      color: #fff;
      padding-top: 2.5rem;
      .row {
        width: 100%;
        max-width: 1200px;
        margin: auto;
      }
      .merriweather-font {
        font-family: Merriweather;
        font-size: 22px;
        font-weight: bold;
      }
      .footer-about-us-nav {
        ul {
          list-style-type: none;
          padding-inline-start: 0;
          li {
            padding: 0;
            display: block;
            font-weight: bold;
            font-size: 12px;
            margin-bottom: 15px;
          }
        }
      }
      a {
        color: #fff;
      }
      .footer-title {
        font-size: 10px;
        margin-bottom: 20px;
        font-weight: bold;
        text-transform: uppercase;
      }
      .nav-legal {
        border-top: 1px #414044 solid;
        margin-top: 50px;
        padding-top: 50px;
        font-size: 12px;
        li {
          display: inline-block;
          margin-left: 25px;
        }
      }
      .footer-download {
        margin-bottom: 60px;
      }
      .logos-eu {
        margin-top: 45px;
      }
    }
  
    header {
      font-family: sk-modernist;
      font-weight: bold;
      .row {
        width: 100%;
        max-width: 1200px;
        margin: auto;
        padding: 2rem;
      }
    }
  
    #content {
      width: 100%;
      max-width: 550px;
      padding: 15px;
      margin: auto;
      .form-signin {
        font-family: sk-modernist;
        label {
          font-weight: bold;
        }
        .btn {
          margin: 1.25rem 0;
          font-weight: bold;
        }
      }
    }
  }
  